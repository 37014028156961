<template>
  <li class="nav-item">
    <router-link :to="to" class="nav-link" :exact="exact">
      <i v-if="old_icon" :class="icon + ' float-left'"></i>
      <span>
        <slot></slot>
      </span>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "i-circle"
    },
    to: [String, Object],
    exact: Boolean,
    old_icon: Boolean
  },
  data() {
    return {};
  }
};
</script>
